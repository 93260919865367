import {CustomQuery} from "./types";

export const getDistributorsIndex: CustomQuery = {
    queryKey: 'listDistributors',
    type: "LIST",
    query: /* GraphQL */ `
        query ListDistributors(
            $filter: ModelDistributorFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    id
                    name
                    description
                    abbreviation
                    phone
                    internationalPhone
                    fax
                    tty
                    contactName
                    contactTitle
                    contactPhone
                    contactEmail
                    createdAt
                    updatedAt
                    links {
                        items {
                            id
                            productId
                            distributorId
                            linkText
                            url
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                        }
                        nextToken
                        startedAt
                        __typename
                    }
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                startedAt
                __typename
            }
        }
    `
}

export const getDistributorProducts: CustomQuery = {
    queryKey: 'getDistributor',
    type: "GET",
    query:  /* GraphQL */ `
        query GetDistributor($id: ID!) {
            getDistributor(id: $id) {
                id
                name
                description
                abbreviation
                phone
                internationalPhone
                fax
                tty
                contactName
                contactTitle
                contactPhone
                contactEmail
                products {
                    items {
                        id
                        distributorId
                        name
                        listPrice
                        manufacturer
                        gtin
                        asin
                        submittedMakerSeller
                        description
                        keywords
                        details
                        technicalSpecifications
                        videoUrl
                        internalNotes
                        prices {
                            items {
                                id
                                productId
                                distributorId
                                price
                                linkText
                                url
                                pricingNotes
                                distributor {
                                    id
                                    name
                                    description
                                    abbreviation
                                    phone
                                    internationalPhone
                                    fax
                                    tty
                                    contactName
                                    contactTitle
                                    contactPhone
                                    contactEmail
                                    createdAt
                                    updatedAt
                                    _version
                                    _deleted
                                    _lastChangedAt
                                }
                                createdAt
                                updatedAt
                                _version
                                _deleted
                                _lastChangedAt
                            }
                        }
                        iso2022s {
                            items
                            {
                                id
                                productID
                                iso2022ID
                                _deleted
                                _version
                                iso2022
                                {
                                    id
                                    class
                                    title
                                    description
                                    plainLanguageTitle
                                    plainLanguageDescription
                                    createdAt
                                    updatedAt
                                    _version
                                    _deleted
                                    _lastChangedAt
                                }
                            }
                        }
                        images {
                            items {
                                id
                                productId
                                articleComponentId
                                discussionId
                                reviewId
                                url
                                prefix
                                key
                                
                                position
                                altText
                                title
                                width
                                height
                                createdAt
                                updatedAt
                                _version
                                _deleted
                                _lastChangedAt
                            }
                            nextToken
                            startedAt
                        }
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                    }
                    nextToken
                    startedAt
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
        }
    `
}
