import {CustomQuery} from "./types";

export const getUserProfile: CustomQuery = {
   queryKey: "getUser",
   type: "GET",
   query:/* GraphQL */ `
       query GetUser($id: ID!) {
           getUser(id: $id) {
               id
               distributorId
               firstName
               lastName
               email
               profile
               enabled
               discussions {
                   items {
                       id
                       userId
                       topicId
                       title
                       body
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                       user {
                           id
                           firstName
                           lastName
                       }
                       comments {
                           items {
                               id
                           }
                       }
                       topic {
                           title
                       }
                       userFollows {
                           items {
                               id
                           }
                       }
                       votes {
                           items {
                               id
                               vote
                           }
                       }
                   }
               }
               profileImage {
                   id
                   productId
                   articleId
                   articleComponentId
                   discussionId
                   reviewId
                   profileUserId
                   bannerUserId
                   url
                   prefix
                   key
                   
                   altText
                   title
                   width
                   height
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               bannerImage {
                   id
                   productId
                   articleId
                   articleComponentId
                   discussionId
                   reviewId
                   profileUserId
                   bannerUserId
                   url
                   prefix
                   key
                   
                   altText
                   title
                   width
                   height
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}

export const getDistributorProfile: CustomQuery = {
   queryKey: "getDistributor",
   type: "GET",
   query: /* GraphQL */ `
       query GetDistributor($id: ID!) {
           getDistributor(id: $id) {
               id
               name
               description
               abbreviation
               phone
               internationalPhone
               fax
               tty
               contactName
               contactTitle
               contactPhone
               contactEmail
               prices {
                   items {
                       id
                       productId
                       distributorId
                       price
                       pricingNotes
                       product {
                           id
                           distributorId
                           name
                           listPrice
                           manufacturer
                           gtin
                           asin
                           submittedMakerSeller
                           description
                           keywords
                           details
                           technicalSpecifications
                           videoUrl
                           internalNotes
                           images {
                               items {
                                   id
                                   productId
                                   articleId
                                   articleComponentId
                                   discussionId
                                   reviewId
                                   profileUserId
                                   bannerUserId
                                   url
                                   prefix
                                   key
                                   
                                   altText
                                   title
                                   width
                                   height
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                               nextToken
                               startedAt
                               __typename
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}

export const getDistributorLinks: CustomQuery = {
   queryKey: "linksByDistributor",
   type: "LIST",
   query: /* GraphQL */ `
       query LinksByDistributor($id: ID!) {
           linksByDistributor(distributorId: $id) {
               items {
                   linkText
                   url
                   id
                   distributor {
                       contactEmail
                       contactName
                       contactPhone
                       contactTitle
                       abbreviation
                       description
                       internationalPhone
                       fax
                       id
                       name
                       phone
                       tty
                   }
               }
               nextToken
           }
       }
   `
}

export const getAuthorProfile: CustomQuery = {
   queryKey: "getAuthor",
   type: "GET",
   query: /* GraphQL */ `
       query GetAuthor($id: ID!) {
           getAuthor(id: $id) {
               id
               name
               about
               phone
               internationalPhone
               fax
               tty
               contactName
               contactTitle
               contactPhone
               contactEmail
               articles {
                   items {
                       id
                       authorID
                       articleID
                       article {
                           id
                           title
                           published
                           wordCount
                           mainImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               url
                               prefix
                               key
                               
                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           authors {
                               items {
                                   id
                                   authorID
                                   articleID
                                   author {
                                       id
                                       name
                                       about
                                       phone
                                       internationalPhone
                                       fax
                                       tty
                                       contactName
                                       contactTitle
                                       contactPhone
                                       contactEmail
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}

export const getProfile: CustomQuery = {
   queryKey: "getUser",
    type: "GET",
   query: /* GraphQL */ `
       query GetUser($id: ID!) {
           getUser(id: $id) {
               id
               distributorId
               firstName
               lastName
               email
               profile
               roles
               enabled
               displayName
               location
               displayLocation
               atMostInterestedIn
               roles
               savedProducts {
                   items {
                       id
                       userID
                       productID
                       user {
                           id
                           distributorId
                           firstName
                           lastName
                           email
                           profile
                           roles
                           enabled
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       product {
                           id
                           distributorId
                           name
                           listPrice
                           manufacturer
                           gtin
                           asin
                           submittedMakerSeller
                           description
                           keywords
                           details
                           technicalSpecifications
                           videoUrl
                           internalNotes
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                           images {
                               items {
                                   id
                                   url
                                   prefix
                                   key
                                   
                                   altText
                                   title
                                   width
                                   height
                               }
                           }
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               bookmarkedArticles {
                   items {
                       id
                       userID
                       articleID
                       user {
                           id
                           distributorId
                           firstName
                           lastName
                           email
                           profile
                           roles
                           enabled
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       article {
                           id
                           title
                           published
                           wordCount
                           mainImage {
                               id
                               productId
                               articleId
                               articleComponentId
                               discussionId
                               reviewId
                               url
                               prefix
                               key
                               
                               altText
                               title
                               width
                               height
                               createdAt
                               updatedAt
                               _version
                               _deleted
                               _lastChangedAt
                               __typename
                           }
                           authors {
                               items {
                                   id
                                   authorID
                                   articleID
                                   author {
                                       id
                                       name
                                       about
                                       phone
                                       internationalPhone
                                       fax
                                       tty
                                       contactName
                                       contactTitle
                                       contactPhone
                                       contactEmail
                                       createdAt
                                       updatedAt
                                       _version
                                       _deleted
                                       _lastChangedAt
                                       __typename
                                   }
                                   createdAt
                                   updatedAt
                                   _version
                                   _deleted
                                   _lastChangedAt
                                   __typename
                               }
                           }
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               followedDiscussions {
                   items {
                       id
                       userID
                       discussionID
                       user {
                           id
                           distributorId
                           firstName
                           lastName
                           email
                           profile
                           roles
                           enabled
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       discussion {
                           id
                           userId
                           topicId
                           title
                           body
                           createdAt
                           updatedAt
                           _version
                           _deleted
                           _lastChangedAt
                           __typename
                       }
                       createdAt
                       updatedAt
                       _version
                       _deleted
                       _lastChangedAt
                       __typename
                   }
                   nextToken
                   startedAt
                   __typename
               }
               ratings {
                   nextToken
                   startedAt
                   __typename
               }
               articleApprovals {
                   nextToken
                   startedAt
                   __typename
               }
               discussions {
                   nextToken
                   startedAt
                   __typename
               }
               comments {
                   nextToken
                   startedAt
                   __typename
               }
               questions {
                   nextToken
                   startedAt
                   __typename
               }
               reviews {
                   nextToken
                   startedAt
                   __typename
               }
               votes {
                   nextToken
                   startedAt
                   __typename
               }
               profileImage {
                   id
                   productId
                   articleId
                   articleComponentId
                   discussionId
                   reviewId
                   profileUserId
                   bannerUserId
                   url
                   prefix
                   key
                   
                   altText
                   title
                   width
                   height
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               bannerImage {
                   id
                   productId
                   articleId
                   articleComponentId
                   discussionId
                   reviewId
                   profileUserId
                   bannerUserId
                   url
                   prefix
                   key
                   
                   altText
                   title
                   width
                   height
                   createdAt
                   updatedAt
                   _version
                   _deleted
                   _lastChangedAt
                   __typename
               }
               createdAt
               updatedAt
               _version
               _deleted
               _lastChangedAt
               __typename
           }
       }
   `
}
